export default {
  bind (el, binding) {
    const shadowRoot = el.attachShadow({ mode: 'open' })
    shadowRoot.innerHTML = binding.value
  },
  update (el, binding) {
    const shadowRoot = el.shadowRoot
    if (shadowRoot) {
      shadowRoot.innerHTML = binding.value
    }
  }
}
