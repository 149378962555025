<template>
  <div>
    <router-view/>
    <div v-show="false">
      <!-- we load bxpeditor here so it opens faster next times -->
      <BxpTextEditor :height="14" id="idDummy" v-model="dummy"></BxpTextEditor>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      dummy: ''
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
