import ServerCache from '@/plugins/ServerCache'
// import firstTimeModals from '@/core/firstTimeModals'
import rememberMeModals from '@/core/rememberMeModals'
import modalBase from '@/core/modalBase'
import auth from '@/core/auth'

const modal = {
  ...modalBase,

  openCustom (title, htmltext, classes, options = { bindings: {} }, onClose) {
    if (!classes) {
      classes = {
        mainClass: 'modal-gtk',
        dialog: 'modal-alert-like-dialog first-time modal-alert-gtk modal-450',
        content: 'modal-alert-gtk'
      }
    }
    return this.open({
      component: () => import('@/components/CustomModal.vue'),
      classes,
      data: {
        title,
        htmltext,
        ...options
      },
      onClose
    })
  },

  async open (modal) {
    const component = await modal.component()
    const name = component.default && component.default.name
    if (name === 'CreateNewWorkModal' && (await auth.isLimitReached(modal.data.workType))) {
      return
    }
    return modalBase.open(modal)
  },

  async openRememberMe (uuid, options = { bindings: {} }, cb = () => {}) {
    const data = rememberMeModals.find(o => o.uuid === uuid)
    if ((await ServerCache.get('rm.' + uuid)) === true) {
      return cb()
    }

    return this.open({
      component: () => import('@/components/CustomModal.vue'),
      classes: {
        mainClass: 'modal-gtk',
        dialog: 'modal-alert-like-dialog first-time modal-alert-gtk modal-450',
        content: 'modal-alert-gtk'
      },
      data: {
        ...data,
        ...options,
        rememberMe: true
      },
      onClose: async (remember, force = true) => {
        if (remember && force) {
          await ServerCache.set('rm.' + uuid, remember)
        }

        if (typeof cb === 'function' && force) {
          cb()
        }
        if (typeof options.onClose === 'function') {
          options.onClose(force)
        }
      }
    })
  },

  // async openFirstTime (uuid, cb = () => {}) {
  //   const data = firstTimeModals.find(o => o.uuid === uuid)
  //   if (!data) {
  //     throw Error(uuid + ' not found!')
  //   }
  //   if (await ServerCache.get('ftm.' + uuid)) {
  //     return cb()
  //   }
  //   await ServerCache.set('ftm.' + uuid, true)
  //   this.open({
  //     component: () => import('@/components/FirstTimeModal.vue'),
  //     classes: {
  //       dialog: 'modal-alert-like-dialog first-time',
  //       content: 'modal-alert-like first-time'
  //     },
  //     data,
  //     onClose: () => {
  //       cb()
  //     }
  //   })
  // }
}

export default modal
