import axios from 'axios'
import auth from '@/core/auth'
import alert from '@/core/alert'
import httpHelper from '@/helpers/httpHelper'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

const localHosts = [
  'localhost',
  '127.0.0.1',
  'w1.bxp.local',
  'w2.bxp.local',
  /^192\.168\.0\.\d+$/,
  /^192\.168\.1\.\d+$/,
  /^192\.168\.2\.\d+$/,
  /^192\.168\.178\.\d+$/
]

export default {

  requestCount: 0,

  currentRequests: {},
  currentRequests2: {},

  isLocal () {
    const hostname = window.location.hostname

    return localHosts.some(entry => {
      if (typeof entry === 'string') {
        return entry === hostname
      } else if (entry instanceof RegExp) {
        return entry.test(hostname)
      }
      return false
    })
  },

  getServerUrl () {
    const domain = ''
    // if (!this.isLocal()) {
    //   domain = 'https://service.bauexperts.de'
    // }
    return `${domain}/api/`
  },

  cancelAllRequests () {
    Object.keys(this.currentRequests2).forEach((requestId) => {
      if (typeof this.currentRequests2[requestId] === 'function') {
        this.currentRequests2[requestId]('All operations manually cancelled.')
      }
      delete this.currentRequests2[requestId]
    })
  },

  new () {
    var instance = axios.create({
      baseURL: `${this.getServerUrl()}`,
      headers: {
        Authorization: 'Bearer ' + auth.getToken(),
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Workspace': httpHelper.getSubdomain(location.hostname)
      }
    })

    instance.interceptors.request.use((config) => {
      const requestId = uuid()
      config.cancelToken = new axios.CancelToken((c) => {
        this.currentRequests2[requestId] = c
      })
      config.headers['X-Request-ID'] = requestId
      return config
    }, (error) => {
      return Promise.reject(error)
    })

    instance.interceptors.response.use((response) => {
      const requestId = response.config.headers['X-Request-ID']
      if (requestId && this.currentRequests2[requestId]) {
        delete this.currentRequests2[requestId]
      }
      return response
    }, (error) => {
      const requestId = error.config?.headers['X-Request-ID']
      if (requestId && this.currentRequests2[requestId]) {
        delete this.currentRequests2[requestId]
      }
      return Promise.reject(error)
    })

    return instance
  },

  async onError (error) {
    if (error && error.message === 'All operations manually cancelled.') {
      return
    }
    if (!error.response) {
      console.error(error)
      alert.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie die Seite neu zu laden oder ' +
      'versuchen Sie es später erneut.')
      window.captureException(error)
    } else {
      let message = null
      if (error.response.data) {
        message = error.response.data.message
        if (error.response.data instanceof Blob) {
          const text = await error.response.data.text()
          try {
            message = JSON.parse(text).message
          } catch {
            message = text
          }
        }
        const notToLogCodes = [400, 401, 403, 404]
        // const isValidationErr = Array.isArray(error.response.data) && error.response.data.some(o => o.validation)
        if (!message && !notToLogCodes.includes(error.response.status)) {
          window.captureMessage('Message empty on rest.js onError.')
          window.captureException(error)
        }
      }

      if (error.response.status === 400) {
        // This means that this is an form validation error
        alert.error('Validierung fehlgeschlagen. Einträge entweder fehlend oder fehlerhaft.')
      } else if (error.response.status === 401) {
        // This means the token is not acceptable
        auth.logout()
        window.router.push({ name: 'login' })
      } else if (error.response.status === 403) {
        alert.error('Sie verfügen nicht über die benötigten Zugriffsrechte.')
      } else if (error.response.status === 404) {
        alert.error('Die angeforderte Ressource wurde nicht gefunden.')
      } else if (error.response.status === 406) {
        // This means that api throw an exception
        alert.error(message || 'Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
      } else if (error.response.status === 429) {
        alert.error(message || 'Maximale Anzahl an Anfragen überschritten. Bitte versuchen Sie es später erneut.')
      } else if (error.response.status === 500) {
        alert.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie die Seite neu zu laden oder ' +
        'versuchen Sie es später erneut.')
      }
    }
  },

  get (url, data, callback, onError, cancel = false, reject) {
    const instance = this.new()
    const dataObj = {
      params: data
    }

    if (cancel) {
      if (this.currentRequests[url]) {
        this.currentRequests[url].cancel()
      }
      this.currentRequests[url] = axios.CancelToken.source()

      instance.CancelToken = axios.CancelToken
      instance.isCancel = axios.isCancel

      dataObj.cancelToken = this.currentRequests[url].token
    }

    this.startProgress()

    instance.get(url, dataObj)
      .then(response => {
        if (response.headers['bxp-appversion'] && auth.user) {
          auth.user.appversion = response.headers['bxp-appversion']
        }
        callback(response.data)
        this.stopProgress()
      })
      .catch(error => {
        // hotfix because 401 should always immediately override err func and logout the user and cancel all requests
        if (typeof onError === 'function' && (!error || !error.response || error.response.status !== 401)) {
          try {
            onError(error)
          } catch (err) {
            console.error(err)
          }
        } else if (!instance.isCancel || !instance.isCancel(error)) {
          this.onError(error)
        }
        if (typeof reject === 'function') {
          reject(error)
        }
        this.stopProgress()
      })
  },

  getSync (url, data, onError, cancel = false) {
    return new Promise((resolve, reject) => this.get(url, data, resolve, onError, cancel, reject))
  },

  restFunc (method, url, data, callback, onError, reject, progressChanged, restOptions = {}) {
    const config = typeof progressChanged === 'function' ? {
      onUploadProgress: evt => (progressChanged(Math.floor(evt.loaded * 100 / evt.total))),
      onDownloadProgress: evt => (progressChanged(Math.floor(evt.loaded * 100 / evt.total)))
    } : {}

    Object.assign(config, restOptions)

    this.startProgress()
    this.new()[method](url, data, config)
      .then(response => {
        callback(response.data)
        this.stopProgress()
      })
      .catch(error => {
        // hotfix because 401 should always immediately override err func and logout the user and cancel all requests
        if (typeof onError === 'function' && (!error || !error.response || error.response.status !== 401)) {
          try {
            onError(error)
          } catch (err) {
            console.error(err)
          }
        } else {
          this.onError(error)
        }
        if (typeof reject === 'function') {
          reject(error)
        }
        this.stopProgress()
      })
  },

  ...['post', 'patch', 'put', 'delete'].reduce((acc, method) => {
    acc[method] = function (...args) {
      this.restFunc(method, ...args)
    }
    return acc
  }, {}),

  ...['postSync', 'patchSync', 'putSync', 'deleteSync'].reduce((acc, method) => {
    acc[method] = function (url, data, onError) {
      return new Promise((resolve, reject) => this[method.replace('Sync', '')](url, data, resolve, onError, reject))
    }
    return acc
  }, {}),

  startProgress () {
    this.requestCount++
    if (Vue.prototype.$sharedData) {
      Vue.prototype.$sharedData.isSubmitting = true
    }

    if (window.nprogress) {
      window.nprogress.start()
    }
  },

  stopProgress () {
    if (this.requestCount > 0) {
      this.requestCount--
    }

    if (this.requestCount === 0) {
      if (Vue.prototype.$sharedData) {
        Vue.prototype.$sharedData.isSubmitting = false
      }

      if (window.nprogress) {
        window.nprogress.done()
      }
    }
  }
}
