import Vue from 'vue'
import auth from '@/core/auth'
import rest from '@/core/rest'
import * as Sentry from '@sentry/vue'
// import SentryRRWeb from '@sentry/rrweb'
import packageJson from '@/../../api/package.json'

export default function () {
  window.captureException = err => {
    if (!window.Sentry) {
      return
    }
    Sentry.withScope(scope => {
      if (auth && auth.user) {
        scope.setUser(auth.user)
      }
      Sentry.captureException(err)
    })
  }

  window.captureMessage = message => {
    if (!window.Sentry) {
      return
    }
    Sentry.withScope(scope => {
      if (auth && auth.user) {
        scope.setUser(auth.user)
      }
      Sentry.captureMessage(message, 'error')
    })
  }

  if (!rest.isLocal()) {
    window.Sentry = Sentry

    Sentry.init({
      Vue,
      dsn: 'https://eb969d871ce743da8ed21ea8bad836d5@o250665.ingest.sentry.io/1435895',
      integrations: [
        /* new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [
            'localhost',
            /^\//,
            /^https:\/\/service\.bauexperts\.de/,
            /^https:\/\/my\.pixelkultur\.net/,
            /^https:\/\/bauexperts\.de/,
            /^https:\/\/pixelkultur\.net/
          ]
        }),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false
        }) */
      ],
      attachProps: true,
      // tracing: true,
      // tracesSampleRate: 0.2, // for development, lower is better I guess
      // tracingOptions: {
      //   trackComponents: true
      // },

      // replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      // replaysOnErrorSampleRate: 1.0,

      sendDefaultPii: true,
      normalizeMaxBreadth: 5000,
      maxValueLength: 512,
      maxBreadcrumbs: 200,
      initialScope: {
        tags: { part: 'frontend' }
      },
      release: 'frontend@' + packageJson.version
      // release: 'front-end@2.0.0' // we'd need to update it on every frontend push, but we don't, so better uncomment it
    })

    Vue.config.errorHandler = function (err) {
      window.captureException(err)
      throw err
    }
  }
}
